import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>IMODIUM® Instant Smelttabletten</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Imodium-Smelttablet-2mg-3D-Frontal-NL.png"
                    width="170"
                    height="102"
                    alt="IMODIUM® Instant Smelttabletten"
                  />
                </div>
                <p>
                  Handige hulp bij diarree. Inname zonder water en zonder
                  slikken. Smelt in enkele&nbsp;seconden op de tong.
                </p>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  Meer informatie
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-15">
            <h1>Oorzaken van diarree</h1>
            <div className="img">
              <img
                src="/assets/files/pages/durchfallmythen.jpeg"
                width="701"
                height="289"
                alt="Oorzaken van diarree"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>De meest voorkomende oorzaken van diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  In veel gevallen, zoals bij een voedselvergiftiging, is de{" "}
                  <strong>oorzaak van de diarree </strong>duidelijk. Soms is het
                  niet altijd even duidelijk waarom men zo vaak naar de wc moet.
                  Hier enkele van de meest voorkomende oorzaken van acute
                  (kortdurende) diarree-aanvallen.
                </p>
              </div>
            </div>
            <h3>Oorzaken acute diarree</h3>
            <ul>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/diarree-op-reis/">Diarree bij reizen</a> (
                <strong>reizigersdiarree</strong>)
              </li>
              <li>
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                  Allergieën of onverdraagzaamheid voor bepaalde soorten voedsel
                </a>
              </li>
              <li>Eten van te veel sterk gekruid of vet voedsel</li>
              <li>Overmatig alcoholgebruik</li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Menstruatie
                </a>{" "}
                (veel vrouwen lijden kort voor of tijdens hun menstruatie aan
                diarree)
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">Stress</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                  Voedselvergiftiging
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
